import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { countryAPI } from '../api/countryAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new countries
export const addNewCountryRequest = // eslint-disable-next-line
  createAsyncThunk('countries/addNewCountryRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await countryAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to getAllCountriesRequest
export const getAllCountriesRequest = createAsyncThunk(
  'countries/getAllCountriesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await countryAPI.all();
      dispatch(setAllCountries(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleCountryRequest = createAsyncThunk(
  'countries/getSingleCountryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await countryAPI.single(dt);
      dispatch(setSingleCountry(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateCountryRequest
export const updateCountryRequest = createAsyncThunk(
  'countries/updateCountryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await countryAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteCountryRequest
export const deleteCountryRequest = createAsyncThunk(
  'countries/deleteCountryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await countryAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allCountries: [],
  singleCountry: {},
  errorInCountry: ''
};

export const countrySlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    setSingleCountry: (state, action) => {
      state.singleCountry = action.payload;
    },
    clearHasError: (state) => {
      state.errorInCountry = '';
    }
  },
  extraReducers: {
    [addNewCountryRequest.rejected]: (state, action) => {
      state.errorInCountry = action.payload;
    },
    [getAllCountriesRequest.rejected]: (state, action) => {
      state.errorInCountry = action.payload;
    },
    [getSingleCountryRequest.rejected]: (state, action) => {
      state.errorInCountry = action.payload;
    },
    [updateCountryRequest.rejected]: (state, action) => {
      state.errorInCountry = action.payload;
    },
    [deleteCountryRequest.rejected]: (state, action) => {
      state.errorInCountry = action.payload;
    }
  }
});
export const { setAllCountries, setSingleCountry, clearHasError } = countrySlice.actions;

export default countrySlice.reducer;
