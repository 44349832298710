import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authorAPI } from '../api/authorAPI';
import { setMessage } from './appSlice';

// async thunk request to Register new category
export const addNewAuthorRequest = // eslint-disable-next-line
  createAsyncThunk('authors/addNewAuthorRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await authorAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All category
export const getAllAuthorsRequest = createAsyncThunk(
  'authors/getAllAuthorsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await authorAPI.all();
      dispatch(setAllAuthors(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleAuthorRequest = createAsyncThunk(
  'authors/getSingleAuthorRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await authorAPI.single(dt);
      dispatch(setSingleAuthor(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateAuthorRequest
export const updateAuthorRequest = createAsyncThunk(
  'authors/updateAuthorRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await authorAPI.update(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteAuthorRequest
export const deleteAuthorRequest = createAsyncThunk(
  'authors/deleteAuthorRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await authorAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allAuthors: [],
  singleAuthor: {},
  errorInAuthor: ''
};

export const authorSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {
    setAllAuthors: (state, action) => {
      state.allAuthors = action.payload;
    },
    setSingleAuthor: (state, action) => {
      state.singleAuthor = action.payload;
    },
    clearHasError: (state) => {
      state.errorInAuthor = '';
    }
  },
  extraReducers: {
    [addNewAuthorRequest.rejected]: (state, action) => {
      state.errorInAuthor = action.payload;
    },
    [getAllAuthorsRequest.rejected]: (state, action) => {
      state.errorInAuthor = action.payload;
    },
    [getSingleAuthorRequest.rejected]: (state, action) => {
      state.errorInAuthor = action.payload;
    },
    [updateAuthorRequest.rejected]: (state, action) => {
      state.errorInAuthor = action.payload;
    },
    [deleteAuthorRequest.rejected]: (state, action) => {
      state.errorInAuthor = action.payload;
    }
  }
});
export const { setAllAuthors, setSingleAuthor, clearHasError } = authorSlice.actions;

export default authorSlice.reducer;
