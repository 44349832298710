import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { provinceAPI } from '../api/provinceAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new provinces
export const addNewProvinceRequest = // eslint-disable-next-line
  createAsyncThunk('provinces/addNewProvinceRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await provinceAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to getAllProvincesRequest
export const getAllProvincesRequest = createAsyncThunk(
  'provinces/getAllProvincesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await provinceAPI.all();
      dispatch(setallProvinces(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleProvinceRequest = createAsyncThunk(
  'provinces/getSingleProvinceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await provinceAPI.single(dt);
      dispatch(setSingleProvince(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateProvinceRequest
export const updateProvinceRequest = createAsyncThunk(
  'provinces/updateProvinceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await provinceAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteProvinceRequest
export const deleteProvinceRequest = createAsyncThunk(
  'provinces/deleteProvinceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await provinceAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allProvinces: [],
  singleProvince: {},
  errorInProvince: ''
};

export const provinceSlice = createSlice({
  name: 'provinces',
  initialState,
  reducers: {
    setallProvinces: (state, action) => {
      state.allProvinces = action.payload;
    },
    setSingleProvince: (state, action) => {
      state.singleProvince = action.payload;
    },
    clearHasError: (state) => {
      state.errorInProvince = '';
    }
  },
  extraReducers: {
    [addNewProvinceRequest.rejected]: (state, action) => {
      state.errorInProvince = action.payload;
    },
    [getAllProvincesRequest.rejected]: (state, action) => {
      state.errorInProvince = action.payload;
    },
    [getSingleProvinceRequest.rejected]: (state, action) => {
      state.errorInProvince = action.payload;
    },
    [updateProvinceRequest.rejected]: (state, action) => {
      state.errorInProvince = action.payload;
    },
    [deleteProvinceRequest.rejected]: (state, action) => {
      state.errorInProvince = action.payload;
    }
  }
});
export const { setallProvinces, setSingleProvince, clearHasError } = provinceSlice.actions;

export default provinceSlice.reducer;
