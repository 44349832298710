import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard/main" replace /> },
        {
          path: 'main',
          element: <DashboardPage />
        },
        { path: 'logout', element: <PageFive /> },
        {
          path: 'blogs',
          children: [
            { path: '', element: <Navigate to="/blogs/all-blogs" replace /> },
            { path: 'all-blogs', element: <BlogsList /> },
            { path: 'add-new-blog', element: <AddNewBlog /> },
            { path: 'all-blogs/:id', element: <AddNewBlog isEdit /> },
            { path: 'all-blogs/delete/:id', element: <DeleteBlog /> }
          ]
        },
        {
          path: 'pages',
          children: [
            { path: '', element: <Navigate to="/pages/all-pages" replace /> },
            { path: 'all-pages', element: <PagesList /> },
            { path: 'add-new-page', element: <AddNewPage /> },
            { path: 'all-pages/:id', element: <AddNewPage isEdit /> },
            { path: 'all-pages/delete/:id', element: <DeletePage /> }
          ]
        },
        {
          path: 'cities',
          children: [
            { path: '', element: <Navigate to="/blogs/all-cities" replace /> },
            { path: 'all-cities', element: <CitiesList /> },
            { path: 'add-new-city', element: <AddNewCity /> },
            { path: 'all-cities/:id', element: <AddNewCity isEdit /> },
            { path: 'all-cities/delete/:id', element: <DeleteCity /> }
          ]
        },
        {
          path: 'places',
          children: [
            { path: '', element: <Navigate to="/places/all-places" replace /> },
            { path: 'all-places', element: <PlacesList /> },
            { path: 'add-new-place', element: <AddNewPlace /> },
            { path: 'all-places/:id', element: <AddNewPlace isEdit /> },
            { path: 'all-places/delete/:id', element: <DeletePlace /> }
          ]
        },

        {
          path: 'category',
          children: [
            { path: '', element: <Navigate to="/category/all-blogs-category" replace /> },
            { path: 'all-blogs-category/delete/:id', element: <DeleteCategory /> },
            { path: 'all-blogs-category', element: <CategoryList /> },
            { path: 'all-blogs-category/:id', element: <CategoryList edit /> }
          ]
        },

        {
          path: 'config',
          children: [
            { path: '', element: <Navigate to="/config/complete-config" replace /> },
            { path: 'complete-config', element: <ConfigManagement /> },
            { path: 'complete-config/update/:id', element: <UpdateBusinessConfig edit /> }
          ]
        },
        {
          path: 'leads',
          children: [
            { path: '', element: <Navigate to="/leads/all-leads" replace /> },
            { path: 'all-leads/delete/:id', element: <DeleteLead /> },
            { path: 'all-leads', element: <LeadsList /> }
          ]
        },
        {
          path: 'tags',
          children: [
            { path: '', element: <Navigate to="/tags/all-tags" replace /> },
            { path: 'all-tags/delete/:id', element: <DeleteTag /> },
            { path: 'all-tags', element: <TagsList /> },
            { path: 'all-tags/:id', element: <TagsList edit /> }
          ]
        },
        {
          path: 'countries',
          children: [
            { path: '', element: <Navigate to="/countries/all-countries" replace /> },
            { path: 'all-countries/delete/:id', element: <DeleteCountry /> },
            { path: 'all-countries', element: <CountryList /> },
            { path: 'all-countries/:id', element: <CountryList edit /> }
          ]
        },
        {
          path: 'provinces',
          children: [
            { path: '', element: <Navigate to="/provinces/all-provinces" replace /> },
            { path: 'all-provinces/delete/:id', element: <DeleteProvince /> },
            { path: 'all-provinces', element: <ProvinceList /> },
            { path: 'all-provinces/:id', element: <ProvinceList edit /> }
          ]
        },
        {
          path: 'faqs',
          children: [
            { path: '', element: <Navigate to="/faqs/all-faqs" replace /> },
            { path: 'all-faqs/delete/:id', element: <DeleteFaq /> },
            { path: 'all-faqs', element: <FaqsList /> },
            { path: 'all-faqs/:id', element: <FaqsList edit /> }
          ]
        },
        {
          path: 'authors',
          children: [
            { path: '', element: <Navigate to="/authors/all-authors" replace /> },
            { path: 'all-authors/delete/:id', element: <DeleteAuthor /> },
            { path: 'all-authors', element: <AuthorList /> },
            { path: 'all-authors/:id', element: <AuthorList edit /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <LandingPage />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));

// Blogs Page imports starts here
const AddNewBlog = Loadable(lazy(() => import('../pages/blogs/AddNewBlog')));
const BlogsList = Loadable(lazy(() => import('../pages/blogs/BlogsList')));
const DeleteBlog = Loadable(lazy(() => import('../pages/blogs/DeleteBlog')));

// Cities Page imports starts here
const AddNewCity = Loadable(lazy(() => import('../pages/city/AddNewCity')));
const CitiesList = Loadable(lazy(() => import('../pages/city/CitiesList')));
const DeleteCity = Loadable(lazy(() => import('../pages/city/DeleteCity')));

// Business Config Routes
const ConfigManagement = Loadable(lazy(() => import('../pages/config/ConfigManagement')));
const UpdateBusinessConfig = Loadable(lazy(() => import('../pages/config/UpdateBusinessConfig')));

// Places Page imports starts here
const AddNewPlace = Loadable(lazy(() => import('../pages/places/AddNewPlace')));
const PlacesList = Loadable(lazy(() => import('../pages/places/PlacesList')));
const DeletePlace = Loadable(lazy(() => import('../pages/places/DeletePlace')));

// Static Page imports starts here
const AddNewPage = Loadable(lazy(() => import('../pages/static/AddNewPage')));
const PagesList = Loadable(lazy(() => import('../pages/static/PagesList')));
const DeletePage = Loadable(lazy(() => import('../pages/static/DeletePage')));

// Leads Page imports starts here
const LeadsList = Loadable(lazy(() => import('../pages/leads/LeadsList')));
const DeleteLead = Loadable(lazy(() => import('../pages/leads/DeleteLead')));

// Category Page imports starts here
const CategoryList = Loadable(lazy(() => import('../pages/category/CategoryList')));
const DeleteCategory = Loadable(lazy(() => import('../pages/category/DeleteCategory')));

// FAQ's Page imports starts here
const FaqsList = Loadable(lazy(() => import('../pages/faqs/FaqsList')));
const DeleteFaq = Loadable(lazy(() => import('../pages/faqs/DeleteFaq')));

// Author's Page imports starts here
const AuthorList = Loadable(lazy(() => import('../pages/authors/AuthorsList')));
const DeleteAuthor = Loadable(lazy(() => import('../pages/authors/DeleteAuthor')));

// Tags Page imports starts here
const TagsList = Loadable(lazy(() => import('../pages/tags/TagsList')));
const DeleteTag = Loadable(lazy(() => import('../pages/tags/DeleteTag')));

// Province Page imports starts here
const DeleteProvince = Loadable(lazy(() => import('../pages/province/DeleteProvince')));
const ProvinceList = Loadable(lazy(() => import('../pages/province/ProvinceList')));

// Country Page imports starts here
const CountryList = Loadable(lazy(() => import('../pages/country/CountryList')));
const DeleteCountry = Loadable(lazy(() => import('../pages/country/DeleteCountry')));

const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
