import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cityAPI } from '../api/cityAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new city
export const addNewCityRequest = // eslint-disable-next-line
  createAsyncThunk('cities/addNewCityRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await cityAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to getAllCitiesRequest
export const getAllCitiesRequest = createAsyncThunk(
  'cities/getAllCitiesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await cityAPI.all();
      dispatch(setAllCities(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleCityRequest = createAsyncThunk(
  'cities/getSingleCityRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await cityAPI.single(dt);
      dispatch(setSingleCity(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateCityRequest
export const updateCityRequest = createAsyncThunk(
  'cities/updateCityRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await cityAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteCityRequest
export const deleteCityRequest = createAsyncThunk(
  'cities/deleteCityRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await cityAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allCities: [],
  singleCity: {},
  errorInCity: ''
};

export const citySlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setAllCities: (state, action) => {
      state.allCities = action.payload;
    },
    setSingleCity: (state, action) => {
      state.singleCity = action.payload;
    },
    clearHasError: (state) => {
      state.errorInCity = '';
    }
  },
  extraReducers: {
    [addNewCityRequest.rejected]: (state, action) => {
      state.errorInCity = action.payload;
    },
    [getAllCitiesRequest.rejected]: (state, action) => {
      state.errorInCity = action.payload;
    },
    [getSingleCityRequest.rejected]: (state, action) => {
      state.errorInCity = action.payload;
    },
    [updateCityRequest.rejected]: (state, action) => {
      state.errorInCity = action.payload;
    },
    [deleteCityRequest.rejected]: (state, action) => {
      state.errorInCity = action.payload;
    }
  }
});
export const { setAllCities, setSingleCity, clearHasError } = citySlice.actions;

export default citySlice.reducer;
