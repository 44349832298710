import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { pageAPI } from '../api/pageAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new Page
export const addNewPageRequest = // eslint-disable-next-line
  createAsyncThunk('terms/addNewPageRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await pageAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All Pages
export const getAllPagesRequest = createAsyncThunk(
  'pages/getAllPagesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await pageAPI.all();
      dispatch(setAllPages(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSinglePageRequest = createAsyncThunk(
  'pages/getSinglePageRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await pageAPI.single(dt);
      dispatch(setSinglePage(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updatePageRequest
export const updatePageRequest = createAsyncThunk(
  'pages/updatePageRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await pageAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deletePageRequest
export const deletePageRequest = createAsyncThunk(
  'pages/deletePageRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await pageAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allPages: [],
  singlePage: {},
  errorInPage: ''
};

export const pageSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setAllPages: (state, action) => {
      state.allPages = action.payload;
    },
    setSinglePage: (state, action) => {
      state.singlePage = action.payload;
    },
    clearHasError: (state) => {
      state.errorInPage = '';
    }
  },
  extraReducers: {
    [addNewPageRequest.rejected]: (state, action) => {
      state.errorInPage = action.payload;
    },
    [getAllPagesRequest.rejected]: (state, action) => {
      state.errorInPage = action.payload;
    },
    [getSinglePageRequest.rejected]: (state, action) => {
      state.errorInPage = action.payload;
    },
    [updatePageRequest.rejected]: (state, action) => {
      state.errorInPage = action.payload;
    },
    [deletePageRequest.rejected]: (state, action) => {
      state.errorInPage = action.payload;
    }
  }
});
export const { setAllPages, setSinglePage, clearHasError } = pageSlice.actions;

export default pageSlice.reducer;
