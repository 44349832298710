import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { placeAPI } from '../api/placeAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register Place
export const addNewPlaceRequest = // eslint-disable-next-line
  createAsyncThunk('places/addNewPlaceRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await placeAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All Places
export const getAllPlacesRequest = createAsyncThunk(
  'places/getAllPlacesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await placeAPI.all();
      dispatch(setAllPlaces(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single Place
export const getSinglePlaceRequest = createAsyncThunk(
  'places/getSinglePlaceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await placeAPI.single(dt);
      dispatch(setSinglePlace(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updatePlaceRequest
export const updatePlaceRequest = createAsyncThunk(
  'places/updatePlaceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await placeAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deletePlaceRequest
export const deletePlaceRequest = createAsyncThunk(
  'places/deletePlaceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await placeAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allPlaces: [],
  singlePlace: {},
  errorInPlace: ''
};

export const placeSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setAllPlaces: (state, action) => {
      state.allPlaces = action.payload;
    },
    setSinglePlace: (state, action) => {
      state.singlePlace = action.payload;
    },
    clearHasError: (state) => {
      state.errorInPlace = '';
    }
  },
  extraReducers: {
    [addNewPlaceRequest.rejected]: (state, action) => {
      state.errorInPlace = action.payload;
    },
    [getAllPlacesRequest.rejected]: (state, action) => {
      state.errorInPlace = action.payload;
    },
    [getSinglePlaceRequest.rejected]: (state, action) => {
      state.errorInPlace = action.payload;
    },
    [updatePlaceRequest.rejected]: (state, action) => {
      state.errorInPlace = action.payload;
    },
    [deletePlaceRequest.rejected]: (state, action) => {
      state.errorInPlace = action.payload;
    }
  }
});
export const { setAllPlaces, setSinglePlace, clearHasError } = placeSlice.actions;

export default placeSlice.reducer;
