import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const authorAPI = {
  add: async (info) => axios.post(`${baseURL}/authors`, info).then((res) => res.data),
  all: async () => axios.get(`${baseURL}/authors`).then((res) => res.data),
  single: async (idToSearch) => axios.get(`${baseURL}/get-author/${idToSearch}`).then((res) => res.data),
  update: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/authors/${id}`, details).then((res) => res.data);
  },
  delete: async (idToSearch) => axios.delete(`${baseURL}/authors/${idToSearch}`).then((res) => res.data)
};
