import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/appSlice';
import userReducer from '../features/userSlice';
import blogReducer from '../features/blogSlice';
import categoryReducer from '../features/categorySlice';
import tagReducer from '../features/tagSlice';
import leadReducer from '../features/leadSlice';
import faqReducer from '../features/faqSlice';
import notificationReducer from '../features/notificationSlice';
import pageReducer from '../features/pageSlice';
import authorReducer from '../features/authorSlice';
import countryReducer from '../features/countrySlice';
import provinceReducer from '../features/provinceSlice';
import cityReducer from '../features/citySlice';
import placeReducer from '../features/placeSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    blogs: blogReducer,
    tags: tagReducer,
    categories: categoryReducer,
    leads: leadReducer,
    faqs: faqReducer,
    notifications: notificationReducer,
    pages: pageReducer,
    authors: authorReducer,
    provinces: provinceReducer,
    countries: countryReducer,
    cities: cityReducer,
    places: placeReducer
  }
});
