import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const cityAPI = {
  add: async (info) => axios.post(`${baseURL}/all-cities`, info).then((res) => res.data),
  all: async () => axios.get(`${baseURL}/all-cities`).then((res) => res.data),
  single: async (idToSearch) => axios.get(`${baseURL}/get-city/${idToSearch}`).then((res) => res.data),
  update: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/single-city-details/${id}`, details).then((res) => res.data);
  },
  delete: async (idToSearch) => axios.delete(`${baseURL}/single-city-details/${idToSearch}`).then((res) => res.data)
};
